import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observer, DidIdResolver } from '@trustcerts/core';
import { ShortenerService } from '@trustcerts/claim-verify';
import { environment } from 'src/environments/environment';
import { ClaimService, Issuer } from './claim.service';
import { ShortService } from './short/short.service';
import { MatSelect } from '@angular/material/select';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AppService } from '../app.service';
import { MatDialog } from '@angular/material/dialog';
import { VerificationSuccessComponent } from '../verification/verification-success/verification-success.component';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-claim',
  templateUrl: './claim.component.html',
  styleUrls: ['./claim.component.scss'],
})
export class ClaimComponent implements OnInit {
  public data: string = '';
  public dataTrusted: SafeHtml = '';
  public hash?: Observer.Hash;
  public issuer?: Issuer;
  public zoomFactor = 1;
  public zoomLevels: number[] = [];
  public selected = 100;
  public loaded = false;
  public mobile = false;
  public claimContent: HTMLElement | undefined;
  public drawerContent: HTMLElement | undefined;
  public toolBar: HTMLElement | undefined;

  private shortenService: ShortenerService;

  constructor(
    private route: ActivatedRoute,
    private claimService: ClaimService,
    public appService: AppService,
    private readonly sanitizer: DomSanitizer,
    private readonly shortService: ShortService,
    public breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private http: HttpClient,
    private router: Router
  ) {
    this.shortenService = new ShortenerService(environment.platform);
    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe((result) => {
        this.mobile = result.matches;
      });
  }

  async ngOnInit(): Promise<void> {
    this.appService.claim = true;
    this.changeZoomBehavior();
    const hash = decodeURI(location.hash.slice(1));
    const id = this.route.snapshot.params.id;
    if (!id) {
      return;
    }
    this.claimService.loadClaim(`${id}#${hash}`).then(
      async (claim) => {
        console.log('claim');
        this.hash = claim.getValidation();
        this.resolveIssuer();
        claim.getHtml().then((value) => {
          this.dataTrusted = this.sanitizer.bypassSecurityTrustHtml(value);
          this.data = value;
        });
        for (let i = 10; i <= 100; ) {
          this.zoomLevels.push(i);
          i = i + 10;
        }
        // this.zoomLevels.push('Seitenbreite');
        // this.zoomLevels.push('Seitengröße');
      },
      (err) => {
        console.log(err);
        this.router.navigate(['/error']);
      }
    );
    const checkElement = async (selector: any) => {
      while (document.querySelector(selector) === null) {
        await new Promise((resolve) => requestAnimationFrame(resolve));
      }
      return document.querySelector(selector);
    };

    checkElement('#claimContent').then((selector) => {
      document.querySelector<HTMLElement>('#claimContent')!.style.width =
        '21cm';
      document.querySelector<HTMLElement>('#claimContent')!.style.height = '0';
      this.zoomFactor = window.innerWidth / selector.offsetWidth;
      if (this.zoomFactor > 1) {
        this.zoomFactor = 1;
      }
      selector.style.transform = 'scale(' + this.zoomFactor + ')';
    });
  }

  async resolveIssuer() {
    if (this.hash) {
      try {
        const did = await DidIdResolver.load(
          this.hash.signature[0].identifier,
          {
            time: this.hash.block.imported
              ? this.hash.block.createdAt
              : this.hash.createdAt,
          }
        );
        const service = did.getService('name');
        this.issuer = await this.http.get<Issuer>(service.endpoint).toPromise();
      } catch (e) {
        console.log(e);
      }
    }
  }

  async showCheck() {
    this.dialog.open(VerificationSuccessComponent, {
      data: { hash: this.hash, issuer: this.issuer },
      maxHeight: '90vh',
      maxWidth: '100vw',
    });
  }

  getLogo() {
    return environment.logo;
  }

  changeZoomBehavior() {
    let scaling = false;

    document.body.addEventListener(
      'wheel',
      (e) => {
        if (e.ctrlKey) e.preventDefault(); //prevent zoom
      },
      { passive: false }
    );

    document.body.addEventListener(
      'keydown',
      (e) => {
        if (e.ctrlKey && e.key === '+') {
          e.preventDefault();
          this.zoom(true);
        }
        if (e.ctrlKey && e.key === '-') {
          e.preventDefault();
          this.zoom(false);
        }
      },
      { passive: false }
    );

    document.body.addEventListener(
      'touchstart',
      (e) => {
        if (e.touches.length === 2) {
          scaling = true;
        }
      },
      { passive: false }
    );

    let previousValue = 0;
    let pinchDifference = 0;

    document.body.addEventListener(
      'touchmove',
      (e) => {
        if (scaling) {
          e.preventDefault();
          const dist = Math.hypot(
            e.touches[0].pageX - e.touches[1].pageX,
            e.touches[0].pageY - e.touches[1].pageY
          );

          if (previousValue > dist) {
            pinchDifference = pinchDifference + (dist - previousValue);
            if (pinchDifference <= -20) {
              this.zoom(false);
              pinchDifference = 0;
            }
          } else if (previousValue < dist) {
            pinchDifference = pinchDifference + (dist - previousValue);
            if (pinchDifference >= 20) {
              this.zoom(true);
              pinchDifference = 0;
            }
          }
          previousValue = dist;
        }
      },
      { passive: false }
    );

    document.body.addEventListener(
      'touchend',
      (e) => {
        if (scaling) {
          scaling = false;
        }
      },
      { passive: false }
    );
  }

  show() {
    // TODO set correct width and height
    const win = window.open(
      '',
      'Certificate',
      'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=800'
    );
    win!.document.body.innerHTML = this.data;
  }

  zoom(zoomIn: boolean) {
    const claim = document.getElementById('claimContent');

    if (zoomIn) {
      if (this.zoomFactor < 1.0) {
        this.zoomFactor = this.zoomFactor + 0.1;
      }
    } else {
      // TODO zooming out when the claim already fits does not make any sense
      // Compare the transformed value with the screen size to find out if it already fits.
      if (this.zoomFactor > 0.1) {
        this.zoomFactor = this.zoomFactor - 0.1;
      }
    }

    claim!.style.transform = 'scale(' + this.zoomFactor + ')';
  }

  setZoom(value: number) {
    const claim = document.getElementById('claimContent');
    this.zoomFactor = value / 100;
    claim!.style.transform = 'scale(' + this.zoomFactor + ')';
  }

  async shorten(url: string) {
    const res = await this.shortService.encode(url);
    const id = await this.shortenService.shorten(res.value, res.iv);
    return `${location.origin}/sc/${id}#${res.key}`;
  }
}
