<mat-drawer-container *ngIf="hash" class="drawerContainer">
  <mat-drawer
    #drawer
    class="drawer"
    id="sideNav"
    mode="side"
    [opened]="!mobile"
  >
    <div fxLayout="column" id="sideContent">
      <div fxLayout="row" fxLayoutAlign="space-between center" id="sideTop">
        <img routerLink="/" [src]="getLogo()" id="logo" />
        <mat-icon (click)="drawer.close()">menu</mat-icon>
      </div>
      <button
        mat-raised-button
        color="accent"
        (click)="showCheck()"
        id="checkButton"
      >
        Prüfbericht anzeigen
      </button>
      <mat-form-field appearance="fill">
        <mat-label>Erstellt am</mat-label>
        <input matInput disabled [value]="hash.createdAt | date: 'medium'" />
        <mat-icon matSuffix>create</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="hash.revokedAt">
        <mat-label>Widerrufen am</mat-label>
        <input matInput disabled [value]="hash.revokedAt | date: 'medium'" />
        <mat-icon matSuffix>block</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Aussteller</mat-label>
        <input matInput disabled [value]="issuer?.name" />
        <mat-icon matSuffix>person</mat-icon>
      </mat-form-field>
      <div class="spacer"></div>
      <footer>
        <a href="//www.trustcerts.de">Copyright by TrustCerts</a> |
        <a href="//www.trustcerts.de/impressum">Impressum</a>
      </footer>
    </div>
  </mat-drawer>
  <mat-toolbar class="drawerHeader">
    <mat-toolbar-row
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxLayoutGap="16px"
    >
      <button
        mat-raised-button
        color="accent"
        (click)="showCheck()"
        fxLFlex="70"
        fxHide.gt-sm
        id="checkButton"
      >
        Prüfbericht anzeigen
      </button>
      <div id="zoomBar" fxLayout="row" fxLayout="center">
        <mat-icon
          class="zoomIcon"
          (click)="zoom(false)"
          *ngIf="zoomFactor > 0.1; else noZoom2"
          >zoom_out</mat-icon
        >
        <ng-template #noZoom2
          ><mat-icon class="noZoom">zoom_out</mat-icon></ng-template
        >
        <mat-icon
          class="zoomIcon"
          (click)="zoom(true)"
          *ngIf="zoomFactor < 1.0; else noZoom"
          >zoom_in</mat-icon
        >
        <ng-template #noZoom
          ><mat-icon class="noZoom">zoom_in</mat-icon></ng-template
        >
      </div>
    </mat-toolbar-row>
    <mat-toolbar-row fxLayout="row" fxLayoutGap="16px" fxHide.gt-xs>
      <!-- <mat-form-field appearance="fill">
        <mat-label>Erstellt am</mat-label>
        <input matInput disabled [value]="hash.createdAt | date: 'medium'" />
        <mat-icon matSuffix>create</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="fill" *ngIf="hash.revokedAt">
        <mat-label>Widerrufen am</mat-label>
        <input matInput disabled [value]="hash.revokedAt | date: 'medium'" />
        <mat-icon matSuffix>block</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Aussteller</mat-label>
        <input matInput disabled [value]="issuer?.name" />
        <mat-icon matSuffix>person</mat-icon>
      </mat-form-field> -->
      <div id="issuer" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon>person</mat-icon><span>{{ issuer?.name }}</span>
      </div>
      <div
        id="status"
        fxLayout="row"
        fxLayoutAlign="start center"
        *ngIf="!hash.revokedAt"
      >
        <mat-icon>check</mat-icon><span>gültig</span>
      </div>
      <div
        id="status"
        fxLayout="row"
        fxLayoutAlign="start center"
        *ngIf="hash.revokedAt"
      >
        <mat-icon>block</mat-icon><span>widerrufen</span>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  <div id="mainContent">
    <div id="claimContent">
      <div id="innerClaimContent" class="mat-elevation-z2">
        <div [innerHTML]="dataTrusted"></div>
      </div>
    </div>
  </div>
</mat-drawer-container>
