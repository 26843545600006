import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CenteredComponent } from './centered/centered.component';
import { ClaimComponent } from './claim/claim.component';
import { ShortComponent } from './claim/short/short.component';
import { FileComponent } from './file/file.component';
import { HostedClaimComponent } from './hosted-claim/hosted-claim.component';
import { QrCodeComponent } from './qr-code/qr-code.component';
import { VerificationFailedComponent } from './verification/verification-failed/verification-failed.component';

// TODO use lazy loading to reduce the amount of code
const routes: Routes = [
  {
    path: '',
    component: CenteredComponent,
    children: [
      {
        path: '',
        component: FileComponent,
      },
      {
        path: 'hash/:encoding/:value',
        component: FileComponent,
      },
      {
        path: 'qr',
        component: QrCodeComponent,
      },
      {
        path: 'hosted/:id',
        component: HostedClaimComponent,
      },
      {
        path: 'sc/:id',
        component: ShortComponent,
      },
      {
        path: 'error',
        component: VerificationFailedComponent,
        data: {
          returnLink: true,
        },
      },
    ],
  },
  {
    path: 'claim/:id',
    component: ClaimComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
