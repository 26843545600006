import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HostedClaimComponent } from './hosted-claim.component';
import { VerificationModule } from '../verification/verification.module';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HostedClaimService } from './hosted-claim.service';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

@NgModule({
  declarations: [HostedClaimComponent],
  providers: [HostedClaimService],
  imports: [
    CommonModule,
    VerificationModule,
    MatCardModule,
    FlexLayoutModule,
    NgxExtendedPdfViewerModule,
  ],
})
export class HostedClaimModule {}
