<div class="padding" fxLayout="column">
  <div
    fxLayout="row"
    *ngIf="hash"
    fxLayout.lt-sm="column"
    fxLayoutAlign="space-evenly start"
    fxLayoutAlign.lt-sm="space-around stretch"
    fxLayoutGap="16px"
  >
    <app-verification-success fxFlex="33"></app-verification-success>
    <ngx-extended-pdf-viewer
      fxFlex
      *ngIf="pdfSrc"
      [src]="pdfSrc"
      height="80vh"
    ></ngx-extended-pdf-viewer>
  </div>
  <app-verification-failed *ngIf="error"></app-verification-failed>
</div>
