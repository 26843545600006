import { Injectable } from '@angular/core';
import { DidNetworks } from '@trustcerts/core';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  claim = false;

  constructor() {
    DidNetworks.add('tc:dev', {
      gateways: ['https://api.gateway1.dev.trustcerts.de'],
      observers: ['https://api.observer1.dev.trustcerts.de'],
    });
    DidNetworks.add('tc:staging', {
      gateways: ['https://api.gateway1.staging.trustcerts.de'],
      observers: ['https://api.observer1.staging.trustcerts.de'],
    });
    DidNetworks.add('tc:prod', {
      gateways: ['https://api.gateway1.prod.trustcerts.de'],
      observers: ['https://api.observer1.prod.trustcerts.de'],
    });
  }
}
