import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { DidIdResolver, Observer } from '@trustcerts/core';
import { Issuer } from '../../claim/claim.service';

export interface DialogSuccessData {
  hash: Observer.Hash;
  issuer: Issuer;
}
@Component({
  selector: 'app-verification-success',
  templateUrl: './verification-success.component.html',
  styleUrls: ['./verification-success.component.scss'],
})
export class VerificationSuccessComponent implements AfterViewInit {
  public resolvedIssuer?: Issuer;

  public hash!: Observer.Hash;

  public opened: boolean;

  @ViewChild(MatAccordion) accordion!: MatAccordion;

  // Workaround for angular component issue #13870
  disableAnimation = true;

  constructor(
    public dialogRef: MatDialogRef<VerificationSuccessComponent>,
    @Inject(MAT_DIALOG_DATA) data: DialogSuccessData
  ) {
    this.hash = data.hash;
    this.resolvedIssuer = data.issuer;
    this.opened = false;
  }

  ngAfterViewInit(): void {
    // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => (this.disableAnimation = false));
  }

  openAll() {
    this.accordion.openAll();
    this.opened = true;
  }

  closeAll() {
    this.accordion.closeAll();
    this.opened = false;
  }

  getIssuer(identifier: string, time: string) {
    return DidIdResolver.load(identifier, { time });
  }
}
