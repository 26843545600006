import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observer } from '@trustcerts/core';
import {
  FileSystemDirectoryEntry,
  FileSystemFileEntry,
  NgxFileDropEntry,
} from 'ngx-file-drop';
import { environment } from 'src/environments/environment';
import { FileService } from './file.service';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
})
export class FileComponent implements OnInit {
  @ViewChild('fileInput', { static: true }) fileInput: any;
  public hash?: Observer.Hash;
  public error?: boolean;

  constructor(
    private fileService: FileService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const encoding = this.route.snapshot.paramMap.get('encoding') ?? 'base58';
    const value =
      this.route.snapshot.paramMap.get('value') ??
      this.route.snapshot.paramMap.get('encoding');
    if (value) {
      this.fileService.verifyHash(encoding, value).then(
        (hash) => (this.hash = hash),
        () => (this.error = true)
      );
    }
  }

  getLogo() {
    return environment.logo;
  }

  public verify(event: any) {
    const files = event.target.files;
    for (const file of files) {
      this.checkFile(file);
    }
  }

  public dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.checkFile(file);
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  private checkFile(file: File) {
    if (environment.dropHybrid) {
      this.fileService.checkQrCode(file, true).catch(() => (this.error = true));
    } else {
      if (file.type === 'application/pdf') {
        this.fileService.checkQrCode(file);
      }
      this.fileService.verifyFile(file).then(
        (hash) => (this.hash = hash),
        () => (this.error = true)
      );
    }
  }

  reset() {
    this.hash = undefined;
    this.error = false;
  }
}
