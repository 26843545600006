import { Injectable } from '@angular/core';
import { Claim, ClaimVerifierService } from '@trustcerts/claim-verify';
import { TemplateVerifierService } from '@trustcerts/template-verify';
import { SignatureVerifierService } from '@trustcerts/signature-verify';
import { DidNetworks, Network } from '@trustcerts/core';
export interface Issuer {
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class ClaimService {
  constructor() {}

  async loadClaim(url: string): Promise<Claim> {
    const networks: Network = DidNetworks.resolveNetwork(url);
    const templateVerifier = new TemplateVerifierService(networks.observers);
    const signatureVerifier = new SignatureVerifierService(networks.observers);
    // TODO replace localhost with current url
    const claimVerifier = new ClaimVerifierService(
      templateVerifier,
      signatureVerifier,
      `${location.origin}/claim`
    );
    return claimVerifier.get(url);
  }
}
