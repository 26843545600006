import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { getHashFromArrayBuffer, Observer } from '@trustcerts/core';
import { SignatureVerifierService } from '@trustcerts/signature-verify';

@Component({
  selector: 'app-hosted-claim',
  templateUrl: './hosted-claim.component.html',
  styleUrls: ['./hosted-claim.component.scss'],
})
export class HostedClaimComponent implements OnInit {
  public hash?: Observer.Hash;
  public error?: any;
  public pdfSrc?: ArrayBuffer;

  constructor(
    private readonly route: ActivatedRoute,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    const id = this.route.snapshot.params.id;
    const host = 'https://creator.dev.trustcerts.de/files';
    const networks = {
      observers: ['https://api.observer1.dev.trustcerts.de'],
    };
    const signatureVerifier = new SignatureVerifierService(networks.observers);
    this.http
      .get(`${host}/${id}`, {
        headers: {
          Authorization: 'Bearer dev',
        },
        responseType: 'arraybuffer',
      })
      .subscribe(async (buffer) => {
        this.pdfSrc = buffer;
        const fileHash = await getHashFromArrayBuffer(buffer);
        this.hash = await signatureVerifier.verifyString(fileHash);
      });
  }
}
