<div *ngIf="hasCamera === false">
  <h2>Es sind leider keine Kameras angeschlossen!</h2>
</div>

<div *ngIf="hasPermission === false">
  <h2>Es sind leider keine Berechtigungen für die Kamera erteilt worden!</h2>
</div>

<div *ngIf="hasCamera === true && hasPermission === undefined">
  <h2>Warten auf Berechtigungen für die Kamera</h2>
</div>

<mat-form-field>
  <mat-label>Camera</mat-label>
  <mat-select [(value)]="selectedDevice">
    <mat-option *ngFor="let device of availableDevices" [value]="device">
      {{ device.label }}
    </mat-option>
  </mat-select>
</mat-form-field>

<!--QR-Scanner-->
<div class="camera-frame" *ngIf="selectedDevice"></div>
<div class="darken" *ngIf="selectedDevice"></div>
<zxing-scanner
  [(device)]="selectedDevice"
  (scanSuccess)="scanSuccessHandler($event)"
  (camerasFound)="camerasFoundHandler($event)"
  (camerasNotFound)="camerasNotFoundHandler($event)"
  (permissionResponse)="permissionFoundHandler($event)"
></zxing-scanner>
