declare let window: any;

export class DynamicEnvironment {
  public get platform() {
    return window.config.verifier;
  }

  public get sentry() {
    return window.config.sentry;
  }

  public get version() {
    return window.config.version;
  }

  public get build() {
    return window.config.build;
  }

  public get logo() {
    return window.config.logo;
  }

  public get network() {
    return window.config.network;
  }

  public get dropHybrid() {
    return window.config.dropHybrid;
  }
}
