import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-centered',
  templateUrl: './centered.component.html',
  styleUrls: ['./centered.component.scss'],
})
export class CenteredComponent {
  constructor() {}
}
