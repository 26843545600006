import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShortenerService } from '@trustcerts/claim-verify';
import { environment } from 'src/environments/environment';
import { ShortService } from './short.service';

@Component({
  selector: 'app-short',
  templateUrl: './short.component.html',
  styleUrls: ['./short.component.scss'],
})
export class ShortComponent implements OnInit {
  private shortenService: ShortenerService;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private shortService: ShortService
  ) {
    this.shortenService = new ShortenerService(environment.platform);
  }

  ngOnInit(): void {
    const value = this.route.snapshot.params.id;
    const key = location.hash.slice(1);
    this.shortenService.resolve(value).then(
      (res) => {
        console.log(res);
        this.shortService
          .decode({ iv: res.iv, value: res.url, key })
          .then((id) => {
            console.log(id);
            this.router.navigate([id.split('/').slice(3).join('/')]);
          });
      },
      (err) => {
        alert('failed to decode url');
        console.log(err);
      }
    );
  }
}
