import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerificationSuccessComponent } from './verification-success/verification-success.component';
import { VerificationFailedComponent } from './verification-failed/verification-failed.component';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
@NgModule({
  declarations: [VerificationSuccessComponent, VerificationFailedComponent],
  imports: [
    TranslateModule,
    CommonModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    MatDialogModule,
    FlexLayoutModule,
    MatExpansionModule,
    MatButtonModule,
    MatInputModule,
    RouterModule,
  ],
  exports: [VerificationSuccessComponent, VerificationFailedComponent],
})
export class VerificationModule {}
