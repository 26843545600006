<div [@.disabled]="disableAnimation">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h1 mat-dialog-title>Dieses Dokument ist authentisch!</h1>
    <button mat-icon-button (click)="openAll()" *ngIf="!opened">
      <mat-icon>unfold_more</mat-icon>
    </button>
    <button mat-icon-button (click)="closeAll()" *ngIf="opened">
      <mat-icon>unfold_less</mat-icon>
    </button>
  </div>

  <mat-accordion multi>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>Datei ist unverändert</mat-panel-title>
        <mat-panel-description>
          {{ hash.hash.slice(0, 15) }}...
          <mat-icon>description</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-form-field appearance="fill">
        <mat-label>Prüfsumme</mat-label>
        <input matInput disabled [value]="hash.hash" />
        <mat-icon matSuffix>fingerprint</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Algorithmus</mat-label>
        <input matInput disabled [value]="'sha256'" />
        <mat-icon matSuffix>functions</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Erstellt am</mat-label>
        <input matInput disabled [value]="hash.createdAt | date: 'medium'" />
        <mat-icon matSuffix>schedule</mat-icon>
      </mat-form-field>
    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="hash.revokedAt">
      <mat-expansion-panel-header>
        <mat-panel-title>Datei ist widerrufen</mat-panel-title>
        <mat-panel-description>
          {{ hash.revokedAt | date }}
          <mat-icon>schedule</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-form-field appearance="fill">
        <mat-label>Erstellt am</mat-label>
        <input matInput disabled [value]="hash.createdAt | date: 'medium'" />
        <mat-icon matSuffix>create</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Widerrufen am</mat-label>
        <input matInput disabled [value]="hash.revokedAt | date: 'medium'" />
        <mat-icon matSuffix>block</mat-icon>
      </mat-form-field>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>Aussteller ist bekannt</mat-panel-title>
        <mat-panel-description>
          {{ resolvedIssuer?.name }}
          <mat-icon>person</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-form-field appearance="fill">
        <mat-label>DID of the issuer</mat-label>
        <input
          matInput
          disabled
          [value]="hash.signature[0].identifier.split('#')[0]"
        />
        <mat-icon matSuffix>person</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Used key</mat-label>
        <input matInput disabled [value]="hash.signature[0].identifier" />
        <mat-icon matSuffix>key</mat-icon>
      </mat-form-field>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>Ausstellung</mat-panel-title>
        <mat-panel-description>
          {{ hash.createdAt | date }}
          <mat-icon>schedule</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-list>
        <mat-list-item>
          <mat-icon mat-list-icon>grid_view</mat-icon>
          <div mat-line>{{ hash.block.id }}</div>
          <div mat-line>Block</div>
        </mat-list-item>
      </mat-list>
    </mat-expansion-panel>
  </mat-accordion>

  <div mat-dialog-actions>
    <button mat-button mat-dialog-close>Close</button>
  </div>
</div>
