import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppRoutingModule } from './app-routing.module';
import { ClaimComponent } from './claim/claim.component';
import { ClaimService } from './claim/claim.service';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FileComponent } from './file/file.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { AppService } from './app.service';
import { QrCodeComponent } from './qr-code/qr-code.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ShortComponent } from './claim/short/short.component';
import { HostedClaimModule } from './hosted-claim/hosted-claim.module';
import { VerificationModule } from './verification/verification.module';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { MatInputModule } from '@angular/material/input';
import { CenteredComponent } from './centered/centered.component';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

function initializeAppFactory(httpClient: HttpClient): () => Observable<any> {
  return () => {
    const domain = 'app';
    return httpClient.get(`assets/config/${domain}.json`).pipe(
      tap((config) => {
        (window as any).config = config;
        if (environment.production) {
          Sentry.init({
            release: environment.build,
            dsn: environment.sentry.url,
            environment: environment.sentry.env,
            integrations: [
              new Integrations.BrowserTracing({
                tracingOrigins: ['localhost', environment.platform],
                routingInstrumentation: Sentry.routingInstrumentation,
              }),
            ],
            // We recommend adjusting this value in production, or using tracesSampler
            // for finer control
            tracesSampleRate: 1.0,
          });
        }
      })
    );
  };
}
@NgModule({
  declarations: [
    AppComponent,
    ClaimComponent,
    FileComponent,
    QrCodeComponent,
    ShortComponent,
    CenteredComponent,
  ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'de',
    }),
    NgxFileDropModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatCardModule,
    MatListModule,
    MatTabsModule,
    MatButtonModule,
    MatSnackBarModule,
    MatIconModule,
    MatSelectModule,
    FlexLayoutModule,
    HttpClientModule,
    ZXingScannerModule,
    HostedClaimModule,
    VerificationModule,
    FormsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatDialogModule,
    MatInputModule,
  ],
  providers: [
    ClaimService,
    AppService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [HttpClient, Sentry.TraceService],
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'de-DE',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
