<div fxLayout="column" fxLayoutGap="16px">
  <mat-card>
    <h2>{{ "VERIFICATION_FAILED.HEAD" | translate }}</h2>
    <mat-card-content [innerHTML]="'VERIFICATION_FAILED.MESSAGE' | translate">
    </mat-card-content>
  </mat-card>
  <a routerLink="/" mat-raised-button color="accent" *ngIf="showLink">
    {{ "CHECK_ANOTHER" | translate }}
  </a>
</div>
