import { Injectable, NgZone } from '@angular/core';
import { DidNetworks, Network, Observer, base58Encode } from '@trustcerts/core';
import { SignatureVerifierService } from '@trustcerts/signature-verify';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';
import QrScanner from 'qr-scanner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(
    private snackbar: MatSnackBar,
    private router: Router,
    private ngZone: NgZone
  ) {
    GlobalWorkerOptions.workerSrc = './assets/pdf.worker.js';
    QrScanner.WORKER_PATH = './assets/qr-scanner-worker.min.js';
  }

  private getVerifier() {
    const networks: Network = DidNetworks.resolveNetwork(environment.network);
    return new SignatureVerifierService(networks.observers);
  }

  async verifyFile(file: File): Promise<Observer.Hash> {
    return this.getVerifier().verifyFile(file);
  }

  async verifyHash(encoding: string, hash: string): Promise<Observer.Hash> {
    switch (encoding) {
      case 'hex':
        const buffer = new Uint8Array(
          hash.match(/[\da-f]{2}/gi)!.map((h) => parseInt(h, 16))
        );
        return this.getVerifier().verify(base58Encode(buffer));
      case 'base58':
        return this.getVerifier().verify(hash);
      default:
        return Promise.reject();
    }
  }

  async checkQrCode(file: File, directOpen = false) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = (event) => {
        const content = new Uint8Array(event.target!.result as ArrayBuffer);
        getDocument(content).promise.then(
          async (pdf) => {
            for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
              await pdf
                .getPage(pageNumber)
                .then((page) => this.pagetoCanvas(page))
                .then((canvas) => {
                  QrScanner.scanImage(canvas).then(
                    (res) => {
                      const claim = res.split('/').pop();
                      if (directOpen) {
                        this.ngZone.run(() =>
                          this.router.navigate(['claim/' + claim]).then()
                        );
                      } else {
                        this.snackbar
                          .open('Found QR-Code in document', 'Open')
                          .onAction()
                          .subscribe(() => {
                            this.router.navigate(['claim/' + claim]).then();
                          });
                      }
                    },
                    (err) => {
                      console.log(err);
                      reject();
                    }
                  );
                });
            }
          },
          (err) => {
            console.log(err);
            reject();
          }
        );
      };
      fileReader.readAsArrayBuffer(file);
    });
  }

  private async pagetoCanvas(page: any) {
    var scale = 1.5;
    var viewport = page.getViewport({ scale });

    // create canvas
    const id = 'canvas-id';
    const can = <HTMLCanvasElement>document.createElement('canvas');
    can.id = id;
    can.classList.add('invisible');
    document.body.appendChild(can);

    //
    // Prepare canvas using PDF page dimensions
    //
    const canvas = <HTMLCanvasElement>document.getElementById(id);
    const canvasContext = canvas.getContext('2d');
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    //
    // Render PDF page into canvas context
    //
    await page
      .render({
        canvasContext: canvasContext!,
        viewport: viewport,
      })
      .promise.then();
    return canvas;
  }
}
