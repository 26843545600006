import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-verification-failed',
  templateUrl: './verification-failed.component.html',
  styleUrls: ['./verification-failed.component.scss'],
})
export class VerificationFailedComponent {
  public showLink: boolean;

  constructor(route: ActivatedRoute) {
    this.showLink = route.snapshot.data.returnLink;
  }
}
