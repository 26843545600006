<mat-card
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutGap="16px"
  *ngIf="!hash && !error"
>
  <ngx-file-drop
    fxFlex
    (onFileDrop)="dropped($event)"
    dropZoneClassName="file-drop-zone"
    [multiple]="false"
    class="dropper"
  >
    <ng-template
      ngx-file-drop-content-tmp
      let-openFileSelector="openFileSelector"
    >
      <div
        fxLayout="column"
        fxLayoutAlign="center center"
        class="file-selector"
        (click)="openFileSelector()"
      >
        <mat-icon id="add-icon">add_circle_outline</mat-icon>
        <br />
        <p>Datei hier ablegen</p>
        <u>Datei auswählen</u>
      </div>
    </ng-template></ngx-file-drop
  >
  <div fxFlex fxLayout="column" fxLayoutAlign="space-between stretch">
    <div>
      <h1>Dokumente schnell verifizieren</h1>
      <p>
        Mit dem TrustVerifier können sie die Echtheit von Dokumenten schnell
        prüfen.
      </p>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center">
      <img [src]="getLogo()" id="logo" />
    </div>
    <!-- <button mat-raised-button color="accent" routerLink="qr">
        Scan <mat-icon>qr_code_2</mat-icon>
      </button> -->
  </div>
</mat-card>
<div fxLayout="column" fxLayoutGap="16px">
  <app-verification-success *ngIf="hash"></app-verification-success>
  <app-verification-failed *ngIf="error"></app-verification-failed>
  <button
    mat-raised-button
    color="accent"
    (click)="reset()"
    *ngIf="error || hash"
  >
    {{ "CHECK_ANOTHER" | translate }}
  </button>
</div>
